import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material'
import React from 'react'
import '../assets/styles/App.css'
import Badge from '../assets/img/badge.svg'

export default function SkillsComponent({ windowWidth }) {
  return (
    <>
      <div id='/beceriler' style={{ height: '7em' }}></div>

      <Grid container justifyContent="center" sx={{ marginBottom: '8em' }}>
        <span className='Skills-title'>
          Beceriler
        </span>

        <Grid item container direction='row' flexWrap={windowWidth <= 576 ? 'wrap' : 'nowrap'} justifyContent='center'>
          <Grid item className='Skills-left' sx={{ margin: windowWidth <= 576 ? '0 0 1em 0' : '0 5em 0 0' }}>
            <Card className='Skills-card'>
              <span className='Skills-title-dev'>Frontend Developer</span>
              <CardContent className='Skills-content'>
                <div className='Skills-box'>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />HTML</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />CSS / SASS</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Javascript</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Bootstrap</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Material UI</span>
                </div>
                <div className='Skills-box'>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />React.Js</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />React Native</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Electron.Js</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Figma</span>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item className='Skills-right' sx={{ margin: windowWidth <= 576 ? '0 0 1em 0' : '0 0 0 0' }}>
            <Card className='Skills-card'>
              <span className='Skills-title-dev'>Backend Developer</span>
              <CardContent className='Skills-content'>
                <div className='Skills-box'>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />PHP</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Laravel</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Phalcon</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Linux</span>
                </div>
                <div className='Skills-box'>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Docker</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />MySql</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Nginx</span>
                  <span className='Skills'><img src={Badge} className='Skills-badge-icon' />Git</span>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid >
    </>
  )
}
