import React, { useEffect, useState } from 'react';
import HeaderComponent from './components/HeaderComponent.jsx';
import WelcomeComponent from './components/WelcomeComponent.jsx';
import AboutComponent from './components/AboutComponent.jsx';
import SkillsComponent from './components/SkillsComponent.jsx';
import ContactComponent from './components/ContactComponent.jsx';
import FooterComponent from './components/FooterComponent.jsx';

function App() {
  const [windowWidth, setWindowWidth] = useState(window.screen.width);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.screen.width);
    };

    window.addEventListener('resize', handleResize);

    // Component unmount edildiğinde event listener'ı temizle
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <HeaderComponent />
      <WelcomeComponent windowWidth={windowWidth} />
      <AboutComponent windowWidth={windowWidth} />
      <SkillsComponent windowWidth={windowWidth} />
      <ContactComponent windowWidth={windowWidth} />
      <FooterComponent />
    </>
  );
}
export default App;