import { Grid } from '@mui/material'
import React from 'react'
import '../assets/styles/App.css'
import hand from '../assets/img/hand.svg'
import MouseIconComponent from './MouseIconComponent.jsx'

export default function WelcomeComponent({ windowWidth }) {
  return (
    <>
      <Grid container direction="row" justifyContent="center" sx={{ height: '100vh', display: 'flex', alignContent: 'center', marginBottom: '2em' }}>
        <Grid>
          <div className='Welcome-img'
            style={{
              width: windowWidth <= 576 ? '200px' : '300px', // Boyutları xs ve sm için 200px olarak ayarla
              height: windowWidth <= 576 ? '200px' : '300px', // Otomatik yükseklik              
            }} />
        </Grid>
        <Grid sx={{ marginLeft: windowWidth <= 576 ? '0' : '5em', marginTop: windowWidth <= 576 ? '2em' : '0', padding: windowWidth <= 576 ? '0 1em' : '0' }}>
          <span className='Welcome-name' style={{ fontSize: windowWidth <= 576 ? '2em' : '3em' }}>Fatih Hıdıroğlu <img src={hand} style={{ width: windowWidth <= 576 ? '25px' : '36px ' }} /> </span>
          <span className='Welcome-title' style={{ fontSize: windowWidth <= 576 ? '1em' : '1.5em' }}>Software Developer</span>
          <span className='Welcome-description' style={{ fontSize: windowWidth <= 576 ? '14px' : '16px' }}>Merhaba! Yazılımcı ve tasarımcıyım. Kodlamayı seviyor, tasarımlar yapmaktan zevk alıyorum.
            Dijital dünyada kendi izimi bırakmak için buradayım. </span>
          <MouseIconComponent windowWidth={windowWidth} />
        </Grid>
      </Grid >
    </>
  )
}
