import { AppBar, Box, Container, IconButton } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import Logo from '../assets/img/fh_logo.svg'
import '../assets/styles/App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Menu } from '@mui/icons-material'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#f9f9f9', // '#282c34',
    },
  },
});

export default function HeaderComponent() {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  // Menü dışında herhangi bir yere tıklandığında menüyü kapat
  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) || !event.target.closest('.hamburger-menu')) {
      setMenuOpen(false);
    }
  };

  // Ekran boyutu değiştiğinde hamburger menüyü otomatik kapat
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 600) { // Ekran genişliği 600px'den büyükse
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Linke tıklandığında menüyü kapatma işlemi durduruluyor
  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="fixed" color='primary' enableColorOnDark sx={{ boxShadow: 'none', borderBottom: '1px solid #55555518' }}>
        <Container className='App-header' sx={{ alignItems: 'center', height: '4em' }}>
          <img
            src={Logo}
            alt={'FH'}
            width={'40px'}
            height={'40px'}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            {/* Hamburger menü */}
            <Menu fontSize='large' sx={{ fill: '#555555', display: { xs: 'block', sm: 'none' } }} onClick={toggleMenu} />
            {/* Hamburger menü içeriği */}
            {menuOpen && (
              <Box
                ref={menuRef}
                sx={{
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  left: 0,
                  zIndex: 1,
                  bgcolor: '#FFFFFF',
                  border: '1px solid #55555518',
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  display: 'grid',
                  justifyContent: 'start',
                  justifyItems: 'start',
                  padding: '2em',
                }}
                className="hamburger-menu" // Class ekleyerek menü dışındaki tıklamaları kontrol edebiliriz
                onClick={handleOutsideClick} // Menü dışındaki tıklamaları işle
              >
                <a href="#" className='link hamburger' onClick={handleLinkClick}>
                  Ana Sayfa
                </a>
                <a href="https://fhblog.vercel.app/" target='_blank' className='link hamburger' onClick={handleLinkClick}>
                  Blog
                </a>
                <a href="#/hakkimda" className='link hamburger' onClick={handleLinkClick}>
                  Hakkımda
                </a>
                <a href="#/beceriler" className='link hamburger' onClick={handleLinkClick}>
                  Beceriler
                </a>
                <a href="#/iletisim" className='link hamburger' onClick={handleLinkClick}>
                  İletişim
                </a>
              </Box>
            )}
            {/* Normal menü (xs ve sm boyutları dışında) */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <a href="#" className='link'>
                Ana Sayfa
              </a>
              <a href="https://fhblog.vercel.app/" target='_blank' className='link'>
                Blog
              </a>
              <a href="#/hakkimda" className='link'>
                Hakkımda
              </a>
              <a href="#/beceriler" className='link'>
                Beceriler
              </a>
              <a href="#/iletisim" className='link'>
                İletişim
              </a>
            </Box>
            {/* GitHub ikonu */}
          </Box>
        </Container>
      </AppBar>
    </ThemeProvider >
  )
}
