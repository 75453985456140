import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'

export default function FooterComponent() {
  const date = new Date();
  let COPYRIGHT = `© FH ${date.getFullYear()} All Rights Reserved`;
  return (
    <Card sx={{ marginTop: '10em', boxShadow: 'none', border: '1px solid #55555518' }}>
      <CardContent className='Footer-card-content'>
        <span className='Footer-title'>FH</span>
        <span className='Contact-text'>{COPYRIGHT}</span>
      </CardContent>
    </Card>
  )
}
