import { AlternateEmail, ArrowRightAlt } from '@mui/icons-material'
import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import WhatsApp from '../assets/img/whatsapp.svg'
import Instagram from '../assets/img/instagram.svg'
import dribbble from '../assets/img/dribbble.svg'
import linkedin from '../assets/img/linkedin.svg'
import github from '../assets/img/github.svg'
import medium from '../assets/img/medium.svg'

export default function ContactComponent({ windowWidth }) {
  return (
    <>
      <div id='/iletisim' style={{ height: '7em' }}></div>
      <Grid container justifyContent="center" sx={{ marginBottom: '8em' }}>
        <span className='Contact-title'>
          İletişim
        </span>

        <Grid container direction={'column'}>
          {/* Üst */}
          <Grid item container direction='row' className='Contact-card-experience' sx={{ marginBottom: windowWidth <= 576 ? '0' : '3em' }}>
            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 3em 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <AlternateEmail sx={{ fontSize: '18px', fill: '#555555' }} />
                <span className='Contact-subtitle'>E-Mail</span>

                <span className='Contact-text'>fatih.hhidiroglu@gmail.com</span>
                <a href="mailto:fatih.hhidiroglu@gmail.com" target="_blank" rel="noreferrer">
                  <span className='Contact-write-me'>Mail Yaz<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>

            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 0 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <img src={WhatsApp} width='18px' height='18px' style={{ background: 'transparent' }} />
                <span className='Contact-subtitle'>WhatsApp</span>
                <span className='Contact-text'>+90 505 093 25 19</span>
                <a href="https://wa.me/905050932519?text=Merhaba Fatih!" target="_blank">
                  <span className='Contact-write-me'>Mesaj Gönder<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>
          </Grid>

          {/* Orta - 1 */}
          <Grid item container direction='row' className='Contact-card-experience' sx={{ marginBottom: windowWidth <= 576 ? '0' : '3em' }}>
            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 3em 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <img src={Instagram} width='18px' height='18px' style={{ background: 'transparent' }} />
                <span className='Contact-subtitle'>İnstagram</span>
                <span className='Contact-text'>ef_hdrgl</span>
                <a href="https://www.instagram.com/ef_hdrgl/" target="_blank">
                  <span className='Contact-write-me'>Profilimi Görüntüle<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>

            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 0 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <img src={linkedin} width='18px' height='18px' style={{ background: 'transparent' }} />
                <span className='Contact-subtitle'>LinkedIn</span>
                <span className='Contact-text'>Fatih Hıdıroğlu</span>
                <a href="https://www.linkedin.com/in/fatih-hidiroglu/" target='_blank' style={{ display: 'contents' }}>
                  <span className='Contact-write-me'>LinkedIn Görüntüle<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>
          </Grid>

          {/* Orta - 2 */}
          <Grid item container direction='row' className='Contact-card-experience' sx={{ marginBottom: windowWidth <= 576 ? '0' : '3em' }}>
            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 3em 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <img src={dribbble} width='16px' height='16px' style={{ background: 'transparent' }} />
                <span className='Contact-subtitle'>Dribbble</span>
                <span className='Contact-text'>fatihhidiroglu</span>
                <a href="https://dribbble.com/fatihhidiroglu" target='_blank' style={{ display: 'contents' }}>
                  <span className='Contact-write-me'>Dribbble Görüntüle<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>

            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 0 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <img src={github} width='16px' height='16px' style={{ background: 'transparent' }} alt="GitHub" />
                <span className='Contact-subtitle'>Github</span>
                <span className='Contact-text'>fatihhidiroglu</span>
                <a href="https://github.com/fatihhidiroglu" target='_blank' style={{ display: 'contents' }}>
                  <span className='Contact-write-me'>Github İncele<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>
          </Grid>

          {/* Alt */}
          <Grid item container direction='row' className='Contact-card-experience' sx={{ marginBottom: windowWidth <= 576 ? '0' : '3em' }}>
            <Card sx={{ minWidth: 220, margin: windowWidth <= 576 ? '0 0 1em 0' : '0 0 0 0', boxShadow: 'none', border: '1px solid #55555518' }}>
              <CardContent className='Contact-card-content'>
                <img src={medium} width='19px' height='19px' style={{ background: 'transparent' }} />
                <span className='Contact-subtitle'>Medium</span>
                <span className='Contact-text'>fatihhidiroglu</span>
                <a href="https://fatihhidiroglu.medium.com/" target='_blank' style={{ display: 'contents' }}>
                  <span className='Contact-write-me'>Medium Görüntüle<ArrowRightAlt sx={{ fontSize: '20px', background: 'transparent' }} /></span>
                </a>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
