import { Button, Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import '../assets/styles/App.css'
import AboutImg from '../assets/img/about.JPG'
import Cv from '../assets/img/cv_document.svg'
import Award from '../assets/img/award.svg'
import Briefcase from '../assets/img/briefcase.svg'
import Cvpdf from '../assets/Fatih HIDIROGLU.pdf'
export default function AboutComponent({ windowWidth }) {
  return (
    <>
      <div id='/hakkimda' style={{ height: '7em' }}></div>
      <Grid container justifyContent="center" sx={{ marginBottom: '8em' }}>
        <span className='About-title'>
          Hakkımda
        </span>

        <Grid item container
          flexWrap={windowWidth > 768 && windowWidth <= 1200 && 'nowrap'}
          direction={windowWidth <= 768 ? 'column' : 'row'}
          alignContent={windowWidth <= 768 && 'center'}
          alignItems={windowWidth <= 768 && 'center'}
          justifyContent='center'
          sx={{
            width: windowWidth > 768 || windowWidth <= 1200 && '100% !important',
            padding: windowWidth > 768 && windowWidth <= 1200 && '0 0 0 25px !important'
          }}>
          <img src={AboutImg}
            width={windowWidth <= 576 ? '250px' : windowWidth <= 768 ? '350px' : windowWidth <= 992 ? '300px' : '430px'}
            height={windowWidth <= 576 ? '250px' : windowWidth <= 768 ? '350px' : windowWidth <= 992 ? '300px' : '430px'}
            style={{
              borderRadius: '15px'
            }} />

          <Grid item container
            direction='column'
            className='About-card'
            sx={{
              width: windowWidth <= 576 ? '90% !important' : windowWidth <= 1200 && '100% !important'
            }}>
            <Grid item container direction='row' className='About-card-experience' style={{ flexWrap: windowWidth <= 576 ? 'wrap' : windowWidth <= 1200 && 'nowrap' }}>
              <Card sx={{
                minWidth: windowWidth <= 360 ? 115 : windowWidth <= 462 ? 124 : windowWidth <= 576 ? 170 : windowWidth > 768 && windowWidth <= 992 ? 180 : 220,
                margin: windowWidth <= 576 ? '1em 0.5em 0 0.5em' : windowWidth <= 768 ? '1em' : windowWidth > 768 && windowWidth <= 1200 ? '0 1em 0 0' : '0 1em 0 0',
                padding: windowWidth <= 576 ? '10px' : '15px',
                boxShadow: 'none',
                border: '1px solid #55555518'
              }}>
                <CardContent className='About-card-content' sx={{ padding: windowWidth <= 576 ? '10px !important' : '15px' }}>
                  <img src={Award} width='18px' height='18px' style={{ background: 'transparent' }} />
                  <span className='About-subtitle' style={{ fontSize: windowWidth <= 360 ? '12px' : '14px' }}>İş Deneyimim</span>
                  <span className='About-work-experience' style={{ fontSize: windowWidth <= 360 ? '10px' : '11px' }}>5 Yıldır Çalışıyorum</span>
                </CardContent>
              </Card>

              <Card sx={{
                minWidth: windowWidth <= 360 ? 115 : windowWidth <= 462 ? 124 : windowWidth <= 576 ? 170 : windowWidth > 768 && windowWidth <= 992 ? 180 : 220,
                margin: windowWidth <= 576 ? '1em 0.5em 0 0.5em' : windowWidth <= 768 ? '1em' : windowWidth > 768 && windowWidth <= 1200 ? '0 1em 0 0' : '0 0 0 0',
                padding: windowWidth <= 576 ? '10px' : '15px',
                boxShadow: 'none',
                border: '1px solid #55555518'
              }}>
                <CardContent className='About-card-content' sx={{ padding: windowWidth <= 576 ? '10px !important' : '15px' }}>
                  <img src={Briefcase} width='18px' height='18px' style={{ background: 'transparent' }} />
                  <span className='About-subtitle' style={{ fontSize: windowWidth <= 360 ? '12px' : '14px' }}>Projelerim</span>
                  <span className='About-work-experience' style={{ fontSize: windowWidth <= 360 ? '10px' : '11px' }}>15 + Projeler</span>
                </CardContent>
              </Card>
            </Grid>

            <Grid item container sx={{ marginTop: '1em' }}>
              <span className='About-article' style={{ padding: windowWidth <= 576 ? '0 1em' : '0 2em', fontSize: windowWidth <= 576 ? '14px' : '15px' }}>Yazılım geliştirme alanında 5 yıllık tecrübem bulunuyor. 2019 yılında Sivas Cumhuriyet Üniversitesi'nden Bilgisayar Programcılığı alanında mezun oldum. Mezuniyetimden sonra Detaysoft'ta React.js ve React Native teknolojileriyle çalışarak ilk iş deneyimimi kazandım. Bu süreçte hem React Native hem de Flutter kullanarak mobil uygulama geliştirmeleri yaptım. Ardından PHP dilini öğrenerek Laravel Framework ile Back-End becerilerimi geliştirmeye başladım. İki yıl boyunca Modanisa'da Full-Stack Developer olarak çalıştım ve Phalcon ile Codeigniter gibi teknolojilerle de kodlama yaptım. Şu anda aktif olarak React.js ve React Native teknolojilerini kullanıyorum. Boş zamanlarımda UI tasarımıyla ilgileniyorum ve Adobe XD'den Figma'ya geçerek bu alanda da kendimi geliştiriyorum. Sürekli olarak Full-Stack Developer becerilerimi ilerletmeye odaklanıyorum.</span>
            </Grid>

            <Grid item container sx={{ marginTop: '2em', display: 'flex', justifyContent: 'flex-end' }}>
              <a download="Fatih HIDIROGLU.pdf" href={Cvpdf} className='About-download-cv'>
                CV İndir
                <img src={Cv} width='20px' style={{ background: 'transparent', marginLeft: '5px' }} />
              </a>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  )
}
